import React from "react"
import { Row, Col } from "react-bootstrap"

export default function CoreValues({ values = [] }) {
  const coreValuesGrid = (
    <Row className="">
      {values.map((val, index) => (
        <Col
          lg="12"
          xl="2"
          key={index}
          className={` mx-auto feature ${index === 0 ? "" : ""}`}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
            className="pt-4"
          >
            <div>
              <h5 className={val.name}>{val.name}</h5>
              <p
                className="mb-0"
                style={{ fontSize: "0.85rem", lineHeight: "25px" }}
              >
                {val.description}
              </p>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
  return (
    <section id="core-values" className="pb-3 bg-white">
      <div className="py-5">
        <h2
          className="text-center text-bold text-primary "
          style={{ fontSize: "2.5rem" }}
        >
          Our Core Values
        </h2>

        <p style={{ fontSize: "1.25rem" }} className=" mb-0 px-4 text-center">
          We are guided by the RIGHT core values
        </p>
        <div className="px-5 mt-5 d-none d-lg-block">{coreValuesGrid}</div>
        {/* <div className="px-4 mt-5 d-none d-md-block d-lg-none">
          {coreValuesGrid}
        </div> */}
        <div className="px-4 mt-4 d-block d-lg-none">{coreValuesGrid}</div>
      </div>
    </section>
  )
}
