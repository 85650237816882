export default {
  name: "How We Work With Clients",
  titleTxt: "Helping enterprises do more with less",
  titleTxtInd40:
    "To drive  Industry 4.0 transformation enterprises require automation, well connected IoT applications and most importantly Artificial intelligence to make sense of the IoT and enterprise data to optimize the industrial processes and decision making.",
  titleTxtInd40_2:
    "However AI talent is both scarce and expensive and AI projects for Industry 4.0 Projects tend to be costly and time consuming because of high infrastructure and service cost to customize intelligent applications.",
  titleTxtProposition:
    "We help enterprises do more with less. Better outcomes, with lesser resources and carbon footprint. We build AI to better manage the Internet of Things (IoT) and power responsible enterprises.",
  titleSubTextPropostion:
    "Vulcan SPOCK suite of Intelligent applications incorporate industry, functional processes and engineering know-how into AI applications that can be deployed >10X faster than typical consulting engagements.",
  description:
    "We work collaboratively with our clients to help solve some of their toughest problems by bringing together our AI expertise and their understanding of the business. While we deliver quick results, we also keep our focus on continuous improvement and innovation to deliver sustainable value to our clients over the long term. We help clients scale and unlock value from AI not just in the lab but on a commercial scale. ",
  description_sm:
    "We work collaboratively with our clients to help solve some of their toughest problems by bringing together our AI expertise and their understanding of the business. ",
  description_2:
    "While we deliver quick results, we also keep our focus on continuous improvement and innovation to deliver sustainable value to our clients over the long term. We help clients scale and unlock value from AI not just in the lab but on a commercial scale. ",
  values: [
    {
      name: "Responsible",
      description:
        "We hold ourselves to high standards of responsibility and accountability. We walk the talk, and exceed expectations for our Clients, Shareholders and our People, through our hard work, creativity and relentless focus on value creation.",
    },
    {
      name: "Innovative",
      description:
        "We love solving business problems through creative application of emerging technologies in an effective and efficient way. We use our technical ability and business savvy to improve the way the world works.",
    },
    {
      name: "Gracious",
      description:
        "We are gracious and work together in harmony by respecting everyone’s views and treat everyone with dignity, courtesy and respect. We encourage diversity in our workplace and value everyone’s capabilities and contributions.",
    },
    {
      name: "High Performing",
      description:
        "We build and operate high performing teams that collaborate and share knowledge to build skills, drive innovation and deliver value and outcomes for our clients while protecting Vulcan and client information and intellectual property. ",
    },
    {
      name: "Trusted",
      description:
        "We build trusted relationships with our Clients, Shareholders and our People through honest communication, being responsive and delivering on our commitments, every time.",
    },
  ],
}
