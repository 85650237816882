import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WindMillImg from "../../static/images/trial/oil_rig.jpg"
import PageHeader from "../partialPages/PageHeader"

import CoreValues from "../partialPages/about/CoreValues"
import Leadership from "../partialPages/home/Leadership"
import ManikImg from "../images/manik.jpeg"
import TauImg from "../../static/images/tauherng.jpg"
import PrashImg from "../../static/images/prashanth.jpeg"
import KockZuiImg from "../../static/images/kock_zui.png"
import NileshImg from "../images/nilesh.jpeg"
import KamalImg from "../images/kamal.jpeg"
import page from "../data/aboutData"

// const leaders = [
//   {
//     name: "Manik Bhandari",
//     title: "Founder, Chief Executive Officer",
//     titles: ["Founder", "Chief Executive Officer"],
//     bio:
//       "Manik is a serial intrapreneur, and now entrepreneur – he set up and successfully scaled Analytics and AI practices at Accenture and then EY, before founding Vulcan AI. ",
//     img: ManikImg,
//     align: "text-right",
//   },
//   {
//     name: "Dr. Kamal Mannar",
//     title: "Head of Applied Intelligence",
//     bio:
//       "Highly accomplished AI scientist with 15+ years of experience in applying AI and machine learning. Passionate about combining innovations in AI and non-traditional data sources to solve high impact business problems that change the way our clients run their business.",
//     img: KamalImg,
//     align: "text-center",
//   },
//   {
//     name: "Nilesh Jalnapure",
//     title: "Head of Intelligent Data Platforms",
//     bio:
//       "Nilesh has designed, architecture, and delivered several Big Data implementations at EY. As an expert in delivering large scale data processing framework, at Vulcan AI, he works to enable the complete delivery of complex AI products and solutions.",
//     img: NileshImg,
//     align: "text-left",
//   },
//   {
//     name: "Tau Herng",
//     title: "Senior AI Scientist",
//     bio:
//       "Tau Herng has delivered large-scale end-to-end data science projects as a Consultant at Accenture, and continues to deliver innovative solutions to clients at Vulcan AI, where he is currently developing Vision AI applications for Quality Control, optimizing models for deployment at the edge.",
//     img: TauImg,
//     align: "text-left",
//   },

//   {
//     name: "Prashanth Kulkarni",
//     title: "Senior Fullstack Engineer",
//     bio:
//       "Prashanth was delivering high performing, highly available, scalable applications at EY and Western Digital for domains like supply chain optimization, tax, insurance, and storage. At Vulcan AI he is building various applications involving Vision AI and IoT.",
//     img: PrashImg,
//     align: "text-left",
//   },
//   {
//     name: "Kock Zui Lim",
//     title: "AI & IoT – Project Lead",
//     bio:
//       "Kock Zui has successfully delivered machine learning and cutting-edge deep learning projects involving precision agriculture, remote sensing imagery, IoT, NLP, multi-dimensional spatio-temporal data analysis, and real-time motion prediction engine on mobile platform.  Currently at Vulcan AI, he is the project lead on a collaboration effort with Ministry of Manpower to use object detection, pose estimation and time-series model to improve workplace safety in Singapore.",
//     img: KockZuiImg,
//     align: "text-left",
//   },
// ]

const about = () => (
  <Layout pageInfo={{ pageName: "about" }}>
    <SEO title={page.name} description={page.titleTxtProposition} />
    <div id="proposition">
      <div className="d-none d-md-block">
        <PageHeader
          bgImg={WindMillImg}
          pgTitle={page.name}
          pgDesc={page.description}
          bgStyleOverRide={{}}
          pClass="text-left"
        ></PageHeader>
      </div>
      <div className="d-block d-md-none bg-white">
        <PageHeader
          bgImg={WindMillImg}
          pgTitle={page.name}
          pgDesc={page.description_sm}
          pgDesc2={page.description_2}
          bgStyleOverRide={{}}
          pClass="text-left"
        ></PageHeader>
      </div>
    </div>
    {/* <Container className="p-5">
      <Industry4
        heading={page.titleTxtInd40}
        heading2={page.titleTxtInd40_2}
        chagllenges={page.chagllenges}
        headingProposition={page.titleTxtProposition}
        headingPropositionSubText={page.titleSubTextPropostion}
        propositions={page.propositions}
      ></Industry4>

      <div className="pt-5">
        <h3>Our Goal</h3>
        <hr />
        <p>{goal}</p>
      </div>
      <div className="pt-5">
        <h3>Our Team</h3>
        <hr />
        <p>{our_team}</p>
      </div>
    </Container>
   */}{" "}
    {/* <Leadership leaders={leaders}></Leadership> */}
    <CoreValues values={page.values}></CoreValues>{" "}
  </Layout>
)
export default about
